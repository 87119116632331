
import { Options, Vue } from 'vue-class-component';

import { JobPricing } from '@/interface/pricing.interface';
import { JobOptions, LANDLINE_PHONE_APPEND_MATCH_LEVEL, OptionsLandlinePhoneAppend } from './jobOptions.interface';

@Options({
  props: {
    disabled: { type: Boolean, default: false },
    options: Object,
    pricing: Object,
  },
  emits: {
    selectOptions: Object,
  },
})
export default class JobOptionsLandlinePhoneAppend extends Vue {
  declare $props: {
    disabled: boolean;
    options: JobOptions;
    pricing: JobPricing;
  }

  public LANDLINE_PHONE_APPEND_MATCH_LEVEL = LANDLINE_PHONE_APPEND_MATCH_LEVEL;

  public selectOptions: OptionsLandlinePhoneAppend = {
    match_level: LANDLINE_PHONE_APPEND_MATCH_LEVEL.individual,
    include_do_not_call_numbers: false,
    non_matches_use_wirelss_append: false,
  }

  public selectedOptions(key: 'include_do_not_call_numbers' | 'non_matches_use_wirelss_append'): void {
    this.selectOptions[key] = !this.selectOptions[key];

    this.triggerEmit();
  }

  public selectedOptionsMatchLevel(value: LANDLINE_PHONE_APPEND_MATCH_LEVEL): void {
    this.selectOptions.match_level = value;

    this.triggerEmit();
  }

  private triggerEmit(): void {
    this.$emit('selectOptions', { ...this.selectOptions });
  }

  created(): void {
    this.selectOptions = { ...this.$props.options.landline_phone_append };
  }
}
