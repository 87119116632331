
import { Options, Vue } from 'vue-class-component';
import { JobPricing } from '@/interface/pricing.interface';
import {
  JobOptions, JOB_OPTIONS_APPEND, OptionsWirelessPhoneAppend, WIRELESS_PHONE_APPEND_APPEND_TYPE,
} from './jobOptions.interface';

@Options({
  props: {
    disabled: { type: Boolean, default: false },
    options: Object,
    pricing: Object,
  },
  emits: {
    selectOptions: Object,
  },
})
export default class JobOptionsLandlinePhoneAppend extends Vue {
  declare $props: {
    disabled: boolean;
    options: JobOptions;
    pricing: JobPricing;
  }

  public JOB_OPTIONS_APPEND = JOB_OPTIONS_APPEND;

  public WIRELESS_PHONE_APPEND_APPEND_TYPE = WIRELESS_PHONE_APPEND_APPEND_TYPE;

  public selectOptions: OptionsWirelessPhoneAppend = {
    match_level: JOB_OPTIONS_APPEND.individual,
    append_type: WIRELESS_PHONE_APPEND_APPEND_TYPE.standard,
    include_do_not_call_numbers: false,
  }

  public selectedOptions(): void {
    this.selectOptions.include_do_not_call_numbers = !this.selectOptions.include_do_not_call_numbers;

    this.triggerEmit();
  }

  public selectedOptionsDropdown(
    key: 'match_level' | 'append_type',
    value: JOB_OPTIONS_APPEND | WIRELESS_PHONE_APPEND_APPEND_TYPE,
  ): void {
    switch (key) {
      case 'match_level':
        this.selectOptions.match_level = value as JOB_OPTIONS_APPEND;
        break;
      case 'append_type':
        this.selectOptions.append_type = value as WIRELESS_PHONE_APPEND_APPEND_TYPE;
        break;
      default:
        break;
    }

    this.triggerEmit();
  }

  private triggerEmit(): void {
    this.$emit('selectOptions', { ...this.selectOptions });
  }

  created(): void {
    this.selectOptions = { ...this.$props.options.wireless_phone_append };
  }
}
