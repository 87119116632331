
import { Options, Vue } from 'vue-class-component';

import { JobPricing } from '@/interface/pricing.interface';
import { JobOptions, OptionsCIMA } from './jobOptions.interface';

@Options({
  props: {
    disabled: { type: Boolean, default: false },
    options: Object,
    pricing: Object,
  },
  emits: {
    selectOptions: Object,
  },
})
export default class JobOptionsCIMA extends Vue {
  declare $props: {
    disabled: boolean;
    options: JobOptions;
    pricing: JobPricing;
  }

  public selectOptions: OptionsCIMA = {
    estimated_credit: false,
    cert: false,
    select: false,
  }

  public selectedOptions(key: keyof OptionsCIMA, disable: string): void {
    this.selectOptions[key] = !this.selectOptions[key];
    if (disable === 'disabledSelect') {
      this.selectOptions.select = false;
    }
    if (disable === 'disabledCert') {
      this.selectOptions.cert = false;
    }
    this.$emit('selectOptions', { ...this.selectOptions });
  }

  created(): void {
    this.selectOptions = { ...this.$props.options.cima };
  }
}
