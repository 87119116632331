
import { Options, Vue } from 'vue-class-component';

import { JobPricing } from '@/interface/pricing.interface';
import { JobOptions, JOB_OPTIONS_APPEND, OptionsEmailAppendAndValidate } from './jobOptions.interface';

@Options({
  props: {
    disabled: { type: Boolean, default: false },
    options: Object,
    pricing: Object,
  },
  emits: {
    selectOptions: Object,
  },
})
export default class JobOptionsLandlinePhoneAppend extends Vue {
  declare $props: {
    disabled: boolean;
    options: JobOptions;
    pricing: JobPricing;
  }

  public JOB_OPTIONS_APPEND = JOB_OPTIONS_APPEND;

  public selectOptions: OptionsEmailAppendAndValidate = {
    match_level: JOB_OPTIONS_APPEND.individual,
  }

  public selectedOptionsDropdown(value: JOB_OPTIONS_APPEND): void {
    this.selectOptions.match_level = value;

    this.$emit('selectOptions', { ...this.selectOptions });
  }

  created(): void {
    this.selectOptions = { ...this.$props.options.email_append_and_validate };
  }
}
