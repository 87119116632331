
import { Options, Vue } from 'vue-class-component';

import { JobPricing } from '@/interface/pricing.interface';
import { JobOptions, OptionsNCOA } from './jobOptions.interface';

@Options({
  props: {
    disabled: { type: Boolean, default: false },
    options: Object,
    pricing: Object,
  },
  emits: {
    selectOptions: Object,
  },
})
export default class JobOptionsNCOA extends Vue {
  declare $props: {
    disabled: boolean;
    options: JobOptions;
    pricing: JobPricing;
  }

  public selectOptions: OptionsNCOA = { months: 18 };

  public selectedMonth(value: 18 | 48): void {
    this.selectOptions = { months: value };

    this.$emit('selectOptions', { ...this.selectOptions });
  }

  created(): void {
    this.selectOptions = { ...this.$props.options.ncoa };
  }
}
