import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "job-options-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoOptions = _resolveComponent("NoOptions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.notNoOptions)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$props.type), {
          key: 0,
          disabled: _ctx.$props.disabled,
          options: _ctx.$props.options,
          pricing: _ctx.$props.pricing,
          onSelectOptions: _ctx.selectedOptions
        }, null, 8, ["disabled", "options", "pricing", "onSelectOptions"]))
      : (_openBlock(), _createBlock(_component_NoOptions, { key: 1 }))
  ]))
}