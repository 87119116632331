
import { Options, Vue } from 'vue-class-component';

import { JobPricing } from '@/interface/pricing.interface';
import { JobOptions, OptionsConsumerPCOA } from './jobOptions.interface';

@Options({
  props: {
    disabled: { type: Boolean, default: false },
    options: Object,
    pricing: Object,
  },
  emits: {
    selectOptions: Object,
  },
})
export default class JobOptionsConsumerPCOA extends Vue {
  declare $props: {
    disabled: boolean;
    options: JobOptions;
    pricing: JobPricing;
  }

  public selectOptions: OptionsConsumerPCOA = {
    add_rooftop_long_lat: false,
    add_business_consumer_flag: false,
  }

  public selectedOptions(key: keyof OptionsConsumerPCOA): void {
    this.selectOptions[key] = !this.selectOptions[key];

    this.$emit('selectOptions', { ...this.selectOptions });
  }

  created(): void {
    this.selectOptions = { ...this.$props.options.consumer_pcoa };
  }
}
