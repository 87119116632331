
import { Options, Vue } from 'vue-class-component';
import { watch, WatchStopHandle } from 'vue';

import { JOB_TYPE } from '@/interface/job.interface';

import ncoa from '@/common/JobOptions/NCOA.vue';
import { JobPricing } from '@/interface/pricing.interface';
import NoOptions from './JobOptions/NoOptions.vue';

/* eslint-disable camelcase */
import cima from './JobOptions/CIMA.vue';
import consumer_pcoa from './JobOptions/ConsumerPCOA.vue';
import email_append_and_validate from './JobOptions/EmailAppendAndValidate.vue';
import landline_phone_append from './JobOptions/LandlinePhoneAppend.vue';
import wireless_phone_append from './JobOptions/WirelessPhoneAppend.vue';
import { EditedOptions, JobOptions } from './JobOptions/jobOptions.interface';
/* eslint-enable camelcase */

@Options({
  components: {
    NoOptions,
    ncoa,
    consumer_pcoa,
    cima,
    landline_phone_append,
    wireless_phone_append,
    email_append_and_validate,
  },
  props: {
    disabled: Boolean,
    type: String,
    options: Object,
    pricing: Object,
  },
  emits: {
    edit: Object,
  },
})
export default class JobOptionsContainer extends Vue {
  declare $props: {
    disabled: boolean;
    type: JOB_TYPE;
    options: JobOptions;
    pricing: JobPricing;
  }

  public notNoOptions = false;

  public watchStopHandle!: WatchStopHandle;

  private accessibleOptionsTypes: string[] = []

  public selectedOptions(value: EditedOptions): void {
    this.$emit('edit', value);
  }

  private checkType(): void {
    this.notNoOptions = this.accessibleOptionsTypes.some((v) => v === this.$props.type);
  }

  created(): void {
    const components = { ...this.$options.components };
    const types: JOB_TYPE[] = (Object.keys(components) as (JOB_TYPE | 'NoOptions')[])
      .filter((v) => v !== 'NoOptions') as JOB_TYPE[];

    this.accessibleOptionsTypes = types;

    this.checkType();

    this.watchStopHandle = watch(() => this.$props.type, () => this.checkType());
  }

  unmounted(): void {
    this.watchStopHandle();
  }
}
